<template>
  <div class="d-flex flex-column flex-root justify-content-center align-items-center">
    <img :style="{width: '200px'}" :src="backgroundImage" />
    <a v-if="_system == 'android'" class="mt-8" href="https://play.google.com/store/apps/details?id=com.ecommerce.chichbong">
      <img class="icon icon--apple" src="/media/android-google-play.png" alt="" />
    </a>
    <a v-if="_system == 'ios'" class="mt-8" href="itms-apps://apple.com/app/id1578603328">
      <img class="icon icon--apple" src="/media/ios-app-store.png" alt="" />
    </a>
  </div>
</template>

<script>
export default {
  name: "Invite",
  metaInfo: {
    meta: [
      { property: 'al:ios:url', content: "autmchichbong://" },
      { property: 'al:ios:app_store_id', content: "1578603328" },
      { property: 'al:ios:app_name', content: "Chich Bong" },
      { property: 'al:android:url', content: "com.ecommerce.chichbong" },
      { property: 'al:android:app_name', content: "Chich Bong" },
      { property: 'al:android:package', content: "com.ecommerce.chichbong" },
    ]
  },
  mounted() {
    
  },
  computed: {
    backgroundImage() {
      return process.env.BASE_URL + "media/logos/logo-chichbong.png";
    },
    _system() {
      var ua = navigator.userAgent.toLowerCase();
      var isAndroid = ua.indexOf("android") > -1;

      if(isAndroid) return 'android';
      return 'ios';
    }
  }
};
</script>
